<template>
  <div class="box-body">
      <form @keydown="form.onKeydown($event)" @submit.prevent="editCarDescription" v-if="onProgress === false">

        <vue-tabs class="panel">
          <v-tab :key="index"
                 :title="locale.toUpperCase()"
                 class="nav-tab-content"
                 v-for="(locale, index) in locales">
            <div class="form-group" v-bind:class="{ 'has-error': form.errors.has(locale + '.content') }">
<!--              <editor :api-key="editorKey"-->
<!--                      :init="editorConfig"-->
<!--                      v-model="form[locale].content"/>-->
              <wysiwyg v-model="form[locale].content"/>
              <HasError :field="locale + '.content'" :form="form"/>
            </div>
          </v-tab>
        </vue-tabs>

        <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

        <Button :disabled="!validateData || form.busy || isDeleted"
                :form="form"
                :loading="form.busy"
                class="btn btn-lg btn-warning pull-right"
                style="min-width: 100px;">
          <i class="fa fa-edit"></i>
        </Button>
      </form>
  </div>
</template>

<script>
import Vue from "vue";
import Form from 'vform';
import {VTab, VueTabs} from 'vue-nav-tabs';
import 'vue-nav-tabs/themes/vue-tabs.css';
// import Editor from '@tinymce/tinymce-vue';
// import EditorConfigData from './../../../assets/data/tinymce-editor-config.json';
import LocaleService from "../../../services/LocaleService";
import wysiwyg from "vue-wysiwyg";
import "vue-wysiwyg/dist/vueWysiwyg.css";

Vue.use(wysiwyg, {});

Form.axios = API.apiClient;

export default {
  name: 'car-description',

  components: {
    Button, HasError, AlertError, AlertErrors,
    VueTabs,
    VTab,
    // 'editor': Editor,
  },

  props: {
    carId: {
      type: [String, Number],
      required: true,
    },
    isDeleted: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    form: null,

    car: null,
    car_description: null,

    locales: [],

    // editorKey: process.env.VUE_APP_TINYMCE_API_KEY,
    // editorConfig: EditorConfigData,

    onProgress: true,
  }),

  async created() {
    try {
      this.locales = await LocaleService.getItems();

      this.form = new Form();

      await this.getCarDescription();
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  computed: {
    validateData: function () {
      return this.carId && this.locales && this.locales.length > 0;
    },
  },

  methods: {
    async getCarDescription() {
      try {
        const car_description_response = await API.apiClient.get('/car/' + this.carId + '/description');

        this.car_description = car_description_response.data;

        this.car_description.translations.forEach(translate_item => {
          this.form[translate_item.locale] = {
            content: translate_item.content,
          }
        });
      } catch (error) {
        errorHandler(error);
      }

      this.onProgress = false;
    },

    async editCarDescription() {
      await this.form.put('/car/' + this.carId + '/description/edit').then(() => {
        showSuccess();
        this.getCarDescription();
      }).catch((error) => {
        errorHandler(error);
      });
    },
  }
}
</script>

<style scoped>

</style>
