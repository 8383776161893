export default {
    async getItems() {
        try {
            const locales_response = await API.apiClient.get("/locales");

            if (
                locales_response &&
                locales_response instanceof Object &&
                locales_response.data
            ) {
                return locales_response.data;
            } else {
                return null;
            }
        } catch (error) {
            errorHandler(error);
        }
    },
};
